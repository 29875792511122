import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Modal,
  Input,
  Button,
  Spin,
  Switch,
} from "antd";
import axios from "axios";
import Notification from "../../utils/Notification";

const { Title } = Typography;
const { TextArea } = Input;

const Home = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeProject, setActiveProject] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [projectStatus, setProjectStatus] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("http://161.97.84.69/api/v1/projects", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      setProjects(data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Notification("Error", error?.response?.data?.message, "error");
    }
  };

  const onClickHandler = (project) => {
    setModalTitle(`Enter your message here [${project.name}]`);
    setMessageBody(project.message);
    setProjectStatus(project.status);
    setActiveProject(project);
    setOpen(true);
  };

  const onSubmit = async (status) => {
    const { _id } = activeProject;

    try {
      setLoading(true);
      await axios.patch(
        "http://161.97.84.69/api/v1/projects",
        {
          _id,
          message: messageBody,
          script_message: messageBody.replace(/\n/g, "\\n"),
          status: status === undefined ? projectStatus : status,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      await getProjects();
      setActiveProject({});
      setProjectStatus(false);
      setOpen(false);
      setLoading(false);
      Notification(
        "Info",
        `${status === undefined ? "Message" : "Status"} updated successfully`,
        "success"
      );
    } catch (error) {
      setLoading(false);
      Notification("Error", error?.response?.data?.message, "error");
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Row gutter={[16, 16]} style={{ marginTop: "0.5rem" }}>
          {projects.length > 0
            ? projects.map((project, idx) => (
                <Col
                  xs={{ span: 12, offset: 0 }}
                  lg={{ span: 6, offset: 1 }}
                  key={idx}
                >
                  <Card
                    hoverable
                    key={project._id}
                    onClick={() => onClickHandler(project)}
                  >
                    <Title level={2}>{project.name}</Title>
                  </Card>
                </Col>
              ))
            : null}
        </Row>

        <Modal
          title={modalTitle}
          open={open}
          onCancel={() => setOpen(false)}
          width={1000}
          footer={null}
        >
          <Row gutter={[16, 16]} style={{ marginTop: "0.5rem" }}>
            <Col xs={{ span: 24, offset: 0 }}>
              Automation status:{" "}
              <Switch checked={projectStatus} onChange={(e) => onSubmit(e)} />
            </Col>
            <Col xs={{ span: 24, offset: 0 }}>
              <TextArea
                rows={25}
                value={messageBody}
                onChange={(e) => setMessageBody(e.target.value)}
              />
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <Button type="primary" onClick={() => onSubmit()}>
                Submit
              </Button>
            </Col>
          </Row>
        </Modal>
      </Spin>
    </div>
  );
};

export default Home;
