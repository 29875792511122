import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginCheck from "./components/Protected/LoginCheck";
import LogoutCheck from "./components/Protected/LogoutCheck";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import SignIn from "./components/SignIn";
import "./App.css";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <LogoutCheck>
                <Home />
              </LogoutCheck>
            }
            key={1}
          />

          <Route
            path="/signin"
            element={
              <LoginCheck>
                <SignIn />
              </LoginCheck>
            }
            key={2}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
