import { notification } from 'antd';

const Notification = (message, description, type)  => {
  notification[type]({
    message,
    description
  });
};

export default Notification;
