import React from "react";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const logOutFromSystem = () => {
    localStorage.clear();
    navigate("/signin");
    window.location.reload();
  };

  const menuItems = [
    {
      key: "home",
      label: "Home",
      onClick: () => navigate("/"),
    },
  ];
  if (localStorage.getItem("accessToken")) {
    menuItems.push({
      key: "logout",
      label: "Log Out",
      onClick: logOutFromSystem,
    });
  } else {
    menuItems.push({
      key: "signin",
      label: "Sign In",
      onClick: () => navigate("/signin"),
    });
  }

  return (
    <div className="container-fluid">
      <Menu
        items={menuItems}
        mode="horizontal"
        style={{ display: "flex", justifyContent: "flex-end" }}
      />
    </div>
  );
};

export default Navbar;
