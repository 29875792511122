import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Image, Spin } from "antd";
import Notification from "../../utils/Notification";
import "./style.css";

const loginImg = "/img/login.svg";

const SignIn = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (value) => {
    (async () => {
      try {
        setLoading(true);
        console.log(value);
        const { data } = await axios.post(
          "http://161.97.84.69/api/v1/signin",
          value
        );
        localStorage.setItem("accessToken", data?.data?.token);
        Notification("Info", "Login successfully", "success");
        form.setFieldsValue({
          email: "",
          password: "",
        });
        setLoading(false);
        navigate("/");
        window.location.reload();
      } catch (error) {
        console.log(error);
        setLoading(false);
        Notification("Error", error?.response?.data?.message, "error");
      }
    })();
  };

  return (
    <div className="base-container">
      <Spin spinning={loading}>
        <Form name="basic" onFinish={onFinish} form={form}>
          <div className="header">Login</div>
          <div className="content">
            <div className="image">
              <Image src={loginImg} preview={false} />
            </div>
            <div className="form">
              <Form.Item name="email">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Input type="email" name="email" placeholder="email" />
                </div>
              </Form.Item>
              <Form.Item name="password">
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input
                    type="password"
                    name="password"
                    placeholder="password"
                  />
                </div>
              </Form.Item>
            </div>
          </div>
          <div align="middle">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default SignIn;
