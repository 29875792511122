// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
}
.base-container .header {
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}
.base-container .content {
  display: flex;
  flex-direction: column;
}
.base-container .content .image {
  width: 21em;
}
.base-container .content .image img {
  width: 100%;
  height: 100%;
}
.base-container .content .form {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.base-container .content .form .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: -moz-fit-content;
  width: fit-content;
}
.base-container .content .form .form-group label {
  font-size: 20px;
}
.base-container .content .form .form-group input {
  margin-top: 6px;
  min-width: 18em;
  height: 37px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 4px;
  margin-bottom: 31px;
  transition: all 250ms ease-in-out;
}
.base-container .content .form .form-group input:focus {
  outline: none;
  box-shadow: 0px 0px 12px 0.8px #3474db b2;
}
`, "",{"version":3,"sources":["webpack://./src/components/SignIn/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,oCAAoC;AACtC;AACA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAkB;EAAlB,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,oCAAoC;EACpC,yBAAyB;EACzB,SAAS;EACT,kBAAkB;EAClB,mBAAmB;EACnB,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,yCAAyC;AAC3C","sourcesContent":[".base-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 3em;\n}\n.base-container .header {\n  font-size: 24px;\n  font-family: \"Open Sans\", sans-serif;\n}\n.base-container .content {\n  display: flex;\n  flex-direction: column;\n}\n.base-container .content .image {\n  width: 21em;\n}\n.base-container .content .image img {\n  width: 100%;\n  height: 100%;\n}\n.base-container .content .form {\n  margin-top: 2em;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.base-container .content .form .form-group {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: fit-content;\n}\n.base-container .content .form .form-group label {\n  font-size: 20px;\n}\n.base-container .content .form .form-group input {\n  margin-top: 6px;\n  min-width: 18em;\n  height: 37px;\n  padding: 0px 10px;\n  font-size: 16px;\n  font-family: \"Open Sans\", sans-serif;\n  background-color: #f3f3f3;\n  border: 0;\n  border-radius: 4px;\n  margin-bottom: 31px;\n  transition: all 250ms ease-in-out;\n}\n.base-container .content .form .form-group input:focus {\n  outline: none;\n  box-shadow: 0px 0px 12px 0.8px #3474db b2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
