import React from "react";
import { Navigate } from "react-router-dom";

const LogoutCheck = ({ children }) => {
  if (localStorage && localStorage.getItem("accessToken")) {
    return children;
  }
  return <Navigate to="/signin" replace />;
};
export default LogoutCheck;
